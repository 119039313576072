<template>
  <profile_stat_card
    :cards="cards"
    :loading="loading"
    :time="stats.addedAt"
    @update="getStats(true)"
    title="Payments"
    :dense="dense"
  ></profile_stat_card>
</template>

<script>
import Stat from "@/mixins/Stat";
export default {
  mixins: [Stat],
  name: "payments_stat",
  data() {
    return {
      config: {
        storageGet: "getPayStatData",
        storageSet: "setPayStatData",
        axiosLink: "getPayStat"
      }
    };
  },
  computed: {
    cards() {
      if (this.stats && Object.keys(this.stats || {}).length === 0) return [];
      let cards = [];
      const { closed, opened } = this.stats;
      if (this.centered) {
        //all payments
        cards.push({
          icon: "money-check-alt",
          count: closed.count + opened.count,
          name: "Number of payments"
        });
        //all money gained
        cards.push({
          icon: "money-bill-wave-alt",
          count: closed.sum + opened.sum,
          money: true,
          name: "Total Amount"
        });
      } else {
        cards.push({
          icon: "door-open",
          count: opened.count,
          name: "Open Matters Payments"
        });
        cards.push({
          icon: "money-bill-wave-alt",
          count: opened.sum,
          money: true,
          name: "Open Matters Amount"
        });
        cards.push({
          icon: "door-closed",
          count: closed.count,
          name: "Closed Matters Payments"
        });
        cards.push({
          icon: "money-check-alt",
          money: true,
          count: closed.sum,
          name: "Closed Matters Amount"
        });
      }

      return cards;
    }
  },
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
