import storage from "@/storage";
import { lauderLarken } from "@/plugins/axios";
import { MessageRequestError } from "@/errors";
import { mapGetters } from "vuex";
export default {
  components: {
    profile_stat_card: () => import("@/components/profile_stat_card")
  },
  data() {
    return {
      loading: false,
      stats: [],
      config: {
        storageGet: "",
        axiosLink: "",
        storageSet: ""
      }
    };
  },
  methods: {
    async getStats(forceUpdate = false) {
      this.loading = true;
      try {
        if (!this.loginState) return;
        let searchForOld = await storage[this.config.storageGet](
          this.token,
          this.db,
          false
        );
        if (searchForOld && !forceUpdate) {
          this.stats = searchForOld;
        } else {
          let { data } = await lauderLarken[this.config.axiosLink](this.token);
          await storage[this.config.storageSet](
            data.data ? data.data : data,
            this.token,
            this.db
          );
          throw new MessageRequestError();
        }
      } catch (e) {
        let dt = await storage[this.config.storageGet](
          this.token,
          this.db,
          false
        );
        if (dt) {
          this.stats = dt;
        }
        console.log(e);
      }
      this.loading = false;
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        this.getStats();
      }
    }
  },
  computed: {
    ...mapGetters({
      db: "laudergetdb",
      loginState: "laudergetLoginState",
      token: "customToken",
      selected: "lauderGetSelectedAss",
      mgmt: "isMgmt"
    })
  }
};
